<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import { required } from "vuelidate/lib/validators";
import * as moment from "moment";
import Multiselect from "vue-multiselect";
import OtpInput from "@bachdgvn/vue-otp-input";
import Common from "../../../helpers/Common";
// import DatePicker from "vue2-datepicker";

import {
  getPayoutList,
  createPayouts,
  approvePayouts,
  virtualAccountList,
  generateOtp,
  getBeneficiary,
} from "@/state/helpers";
import {
  FETCH_PAYOUT_LIST,
  EXPORT_PAYOUTS_INTO_EXCEL,
  FETCH_PAYOUT_LIST_MASTER_MERCHANT,
  APPROVE_PAYOUTS_VIA_EMAIL,
  VERIFY_PAYOUTS_VIA_EMAIL,
  FETCH_VIRTUAL_ACCOUNTS,
  GENERATE_OTP_PAYOUT,
  GENERATE_OTP_PAYOUT_VIA_EMAIL,
  GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  RESEND_OTP_PAYOUT_VIA_EMAIL,
  RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY,
  FETCH_BENEFICIARY_TABLE,
  FETCH_MERCHANT_ENTITY_DETAILS,
  PAYOUT_FILE_UPLOAD,
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST,
  UPDATE_PAYOUT_STATUS,
} from "@/state/actions.type";
// import FilterBtn from "../../../components/filter-btn.vue";
// import PayoutForm from './payout-form.vue';
import DownloadBtn from '../../../components/download-btn.vue';
import Filters from '../../../components/filters.vue';
import PerPage from '../../../components/per-page.vue';
import SearchBy from '../../../components/search-by.vue';

export default {
  components: {
    Layout,
    // PageHeader,
    Spinner,
    Multiselect,
    OtpInput,
    // FilterBtn,
    // PayoutForm,
    DownloadBtn,
    // DatePicker,
    Filters,
    PerPage,
    SearchBy
  },

  data() {
    return {
      activeTab: "payout",
      createPayoutToShow:true,
      searchBy: {
        key: "Merchant Order Ref",
        value: ""
      },
      viewDetailsModalOpen:false,
      viewDetailsData:{},
      split_payouts: true,
      selectedChannel: "",
      uploadedFile: null,
      fileUploadSuccess: false,
      currentPageTitle: "statement",
      advancedFilterClosed: true,
      selectedTransactionRefs: [],
      selectAvailableInPage: false,
      selectAllTransactions: false,
      currentPage: 1,
      // mor_filter: "",
      submitted: false,
      showApprove: false,
      showUploadmodal: false,
      showVerify: false,
      enterMobile: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      merchants: [],
      verifyAction: "",
      isEdit: false,
      sorting_data: {
        "sort_by_key": "",
        "order": ""
      },
      fields: [
        {
          key: "selectpayout",
          label: "",
          tooltip: "views.payouts.table.tooltip.select",
          sortable: false,
          thClass: "",
          tdClass: "",
        },
        {
          key: "status",
          label: "views.payouts.table.fields.status",
          tooltip: "views.payouts.table.tooltip.status",
          sortable: true,
        },
        {
          key: "merchant_txn_ref",
          label: "views.payouts.table.fields.merchant_transaction_reference",
          tooltip:
              "views.payouts.table.tooltip.merchant_transaction_reference",
          sortable: true,
        },
        {
          key: "account_name",
          label: "views.payouts.table.fields.account_name_1",
          tooltip: "Beneficiary Account name",
          sortable: true,
        },
        {
          key: "bank_name",
          label: "views.payouts.table.fields.bank_name",
          tooltip: "views.payouts.table.fields.bank_name",
          sortable: true,
        },
        {
          key: "to_account_no",
          label: "views.payouts.table.fields.beneficiary_account_number",
          tooltip:
              "views.payouts.table.tooltip.beneficiary_account_number",
          sortable: true,
        },
        {
          key: "amount",
          label: "views.payouts.table.fields.amount",
          tooltip: "views.payouts.table.tooltip.amount",
          sortable: true,
        },
        {
          key: "email_sent",
          label: "views.payouts.table.fields.sent_email",
          tooltip: "views.payouts.table.fields.sent_email",
          sortable: true,
        },
        {
          key: "sms_sent",
          label: "views.payouts.table.fields.sent_sms",
          tooltip: "views.payouts.table.fields.sent_sms",
          sortable: true,
        },
        {
          key: "due_date",
          label: "views.payouts.table.fields.scheduled_time",
          tooltip: "views.payouts.table.tooltip.scheduled_time",
          sortable: true,
        },
        {
          key: "vaaccount_no",
          label: "views.payouts.table.fields.virtual_account_number",
          tooltip:
              "views.payouts.table.tooltip.virtual_account_number",
          sortable: true,
        },
        {
          key: "verified_status",
          label: "views.payouts.table.fields.verify_status",
          sortable: true,
          thClass: "",
          tdClass: "",
          tooltip:
              "views.payouts.table.tooltip.verify_status",
        },
        {
          key: "verified_by",
          label: "views.payouts.table.fields.verified_by",
          tooltip:
              "views.payouts.table.tooltip.verified_by",
          sortable: true,
          thClass: "",
          tdClass: "",
        },
        {
          key: "approved_status",
          label: "views.payouts.table.fields.approval_status",
          sortable: true,
          tooltip:
              "views.payouts.table.tooltip.approval_status",
        },
        {
          key: "approved_by",
          label: "views.payouts.table.fields.approved_by",
          tooltip:
              "views.payouts.table.tooltip.approved_by",
          sortable: true,
        },
        {
          key: "approved_time",
          label: "views.payouts.table.fields.approved_time",
          tooltip: "views.payouts.table.tooltip.approved_time",
          sortable: true,
        },
        {
          key: "status_channel_reason",
          label: "views.payouts.table.fields.tx_status",
          tooltip: "views.payouts.table.tooltip.tx_status",
          sortable: true,
        },
        {
          key: "txn_ref",
          label: "views.payouts.table.fields.transaction_reference",
          tooltip:
              "views.payouts.table.tooltip.transaction_reference",
          sortable: true,
        },
        {
          key: "action",
          label: "",
          tooltip: "",
          sortable: false,
          stickyColumn: true,
        },
      ],
      filters: {
        approved_status: "",
        status: [],
        verified_status: "",
      },
      from: "",
      to: "",
      fromDate: "",
      toDate: "",
      payoutFilterStatus: [
        "Success",
        "Created",
        "Failed",
        "Initiated",
        "Pending",
        "Cancelled",
        "Rejected",
      ],
      payoutApprovalFilterStatus: ["Approved", "Pending", "Rejected"],
      payoutVerifyFilterStatus: ["Verified", "Pending", "Rejected"],
      // account_name: "",
      // name: "",
      payoutApprove: {
        otp: "",
        saveOtp: false
      },

      payoutVerify: {
        otp: "",
        saveOtp: false
      },

      payoutMobile: {
        mobileNo: "",
      },
      editItem: null
    };
  },

  created() {
    if (this.isMasterMerchant) {
      this.$store
        .dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: { source: "default" },
        })
        .then((response) => {
          if (response.content.length > 0) {
            this.refreshRecord();
          }
        });
    } else {
      this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`);
      this.refreshRecord();

      this.$store.dispatch(`virtualaccount/${FETCH_VIRTUAL_ACCOUNTS}`);
      this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, {});
      this.$store
        .dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
          country: [],
          paymentMethod: "",
          paymentType: "PAYOUT",
        })
        .then(() => {
          if (
            this.paymentChannelCredsList &&
            this.paymentChannelCredsList.length == 1
          ) {
            this.selectedChannel = this.paymentChannelCredsList[0];
          }
        });
    }
  },

  computed: {
    paymentChannelCredsList() {
      let channels = this.$store.state.paymentChannels.channels;
      let channelList = [];
      for (let psp in channels) {
        if (channels[psp].onboarding_status.status == "Completed") {
          channelList.push(psp);
        }
      }
      return channelList;
      // return this.$store.state.paymentChannels.channels;
    },

    minimunDate() {
      return moment().format("YYYY-MM-DDThh:mm");
    },

    // title() {
    //   return this.$t("menuitems.payouts.list.payouts");
    // },

    // items() {
    //   return [
    //     {
    //       text: this.$t("menuitems.payouts.list.payouts"),
    //       active: true,
    //     },
    //     {
    //       text: this.$t("menuitems.payouts.list.vendor_bank"),
    //       active: true,
    //     },
    //   ];
    // },

    payoutsDataFromStore() {
      return this.$store.state.payouts.content;
    },

    payoutTotalElementsFromStore() {
      return this.$store.state.payouts.total_elements;
    },

    rows() {
      return this.$store.state.payouts.total_elements;
    },

    isFetchingTransactions() {
      return this.$store.state.payouts.isFetchingData;
    },

    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    isVerifyPayoutsEnabledData() {
      return this.$store.getters["merchant/getMerchantEntityDetails"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  validations: {
    payoutApprove: {
      otp: { required },
    },
    payoutVerify: {
      otp: { required },
    },
    payoutMobile: {
      mobileNo: { required },
    },
  },

  watch: {
    currentPage() {
      this.refreshRecord().then(() => {

        this.selectAvailableInPage = true;

        if(!this.selectAllTransactions) {
          this.checkAllInPageAreSelected();
        } else {
          this.pushPayoutTransactionsInSeleted();
        }
      });
    },
    isVerifyPayoutsEnabledData() {
      let fieldsToToggle = ['verified_status'];
      let thTdClass = "";
      if (this.isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled) {
        if (!this.isMasterMerchant) {
          fieldsToToggle = [...fieldsToToggle, 'verified_by'];
        }
      } else {
        thTdClass = "d-none";
        if (!this.isMasterMerchant) {
          fieldsToToggle = [...fieldsToToggle, 'verified_by'];
        }
      }
      this.fields = this.fields.map((field) => {
        if(fieldsToToggle.includes(field.key)) {
          field.thClass = thTdClass;
          field.tdClass = thTdClass;
        }
        return field;
      });
    },
    // 'from': function(newFromDate) {
    //   if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
    //     const toDate = new Date(newFromDate);
    //     console.log(toDate)
    //     toDate.setHours(23, 59, 59); // Set time to the end of the day
    //     this.to = toDate;
    //   }
    //   else{
    //     this.to = "";
    //   }
    // },
  },

  mounted() {
    this.$root.$on('hide-payout-modal', () => {
      this.isEdit = false;
    })
  },

  methods: {
    ...getPayoutList,
    ...createPayouts,
    ...approvePayouts,
    ...virtualAccountList,
    ...generateOtp,
    ...getBeneficiary,

    openUploadModal() {
      this.showUploadmodal = true;
    },

    hideUploadModal() {
      this.showUploadmodal = false;
    },

    approveModal(action) {
      this.$store
        .dispatch(`payouts/${GENERATE_OTP_PAYOUT_VIA_EMAIL}`)
        .then((response) => {
          this.approveAction = action;
          if(response.approve_otp_bypass_allowed) {
            this.approve(true);
          } else {
            this.submitted = false;
            this.showApprove = true;
  
            this.resetPayoutApprove();
            this.resetPayoutMobile();
          }
        })
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
        });
    },

    verifyModal(action) {
      this.$store
        .dispatch(`payouts/${GENERATE_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY}`)
        .then((response) => {
          this.verifyAction = action;
          if(response.verify_otp_bypass_allowed) {
            this.verify(true);
          } else {
            this.submitted = false;
            this.showVerify = true;
  
            this.resetPayoutVerify();
            this.resetPayoutMobile();
          }
        })
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
        });
    },

    hideMobile() {
      this.submitted = false;
      this.enterMobile = false;
      this.resetPayoutMobile();
    },

    hideApprove() {
      this.submitted = false;
      this.showApprove = false;
      this.resetPayoutApprove();
    },

    hideVerify() {
      this.submitted = false;
      this.showVerify = false;
      this.resetPayoutVerify();
    },

    getNotVerifiedData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "not-verified";
      this.filters = {
        approved_status: "Pending",
        status: ["Created"],
        verified_status: "Pending",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
      if (this.userPermissions.includes("verify-payouts")) {
        this.fields[0].thClass = "";
        this.fields[0].tdClass = "";
      } else {
        this.fields[0].thClass = "d-none";
        this.fields[0].tdClass = "d-none";
      }
    },

    getNotApprovedData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "not-approved";
      this.filters = {
        approved_status: "Pending",
        status: ["Created"],
        verified_status: "Verified",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
      if (this.userPermissions.includes("approve-payouts")) {
        this.fields[0].thClass = "";
        this.fields[0].tdClass = "";
      } else {
        this.fields[0].thClass = "d-none";
        this.fields[0].tdClass = "d-none";
      }
    },

    getStatementData() {
      this.selectedTransactionRefs = [];
      this.selectAvailableInPage = false;
      this.currentPageTitle = "statement";
      this.filters = {
        approved_status: "",
        status: [],
        verified_status: "",
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.refreshRecord();
    },

    getFormattedDate(isoDate) {
      if (isoDate == "0001-01-01T00:00:00Z" || isoDate == "") {
        return "-";
      } else {
        return moment(isoDate).format("MMMM Do YYYY, h:mm:ss a");
      }
    },

    checkAllInPageAreSelected() {
      this.selectAvailableInPage = true;
      for(let element of this.payoutsDataFromStore) {
        if(!this.selectedTransactionRefs.includes(element.txn_ref)) {
          this.selectAvailableInPage = false;
          break;
        }
      }
    },

    toggleSelectPayout(txnRef) {
      this.selectAllTransactions = false;

      if(this.selectedTransactionRefs.includes(txnRef)) {
        this.selectedTransactionRefs = this.selectedTransactionRefs.filter(element => element !== txnRef);
      } else {
        this.selectedTransactionRefs.push(txnRef);
      }

      this.checkAllInPageAreSelected();

      if(this.selectedTransactionRefs.length === this.payoutTotalElementsFromStore) {
        this.selectAllTransactions = true;
      } else {
        this.selectAllTransactions = false;
      }
    },

    pushPayoutTransactionsInSeleted() {
      this.payoutsDataFromStore.map((element) => {
        if(!this.selectedTransactionRefs.includes(element.txn_ref)) {
          this.selectedTransactionRefs.push(element.txn_ref)
        }
      });
    },

    selectAllInPage(value) {
      this.selectAllTransactions = false;

      if(value) {
        this.pushPayoutTransactionsInSeleted();
      } else {
        const unselectedItems = this.payoutsDataFromStore.map((element) => element.txn_ref);
        this.selectedTransactionRefs = this.selectedTransactionRefs.filter(element => !unselectedItems.includes(element));
      }

      if(this.selectedTransactionRefs.length === this.payoutTotalElementsFromStore) {
        this.selectAllTransactions = true;
      } else {
        this.selectAllTransactions = false;
      }
    },

    selectAllTxn() {
      this.selectAllTransactions = true;
      this.selectAvailableInPage = true;

      this.pushPayoutTransactionsInSeleted();
    },

    refreshRecord() {
      if (this.searchBy.value) {
        this.filters.txn_ref = this.searchBy.value.trim()
      } else {
        delete this.filters["txn_ref"];
      }

      if(this.toDate) {
        this.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').toISOString();
      }else{
        this.to = new Date().toISOString()
      }

      if(!this.fromDate) {
        this.from = moment('2018-01-01T00:00:00.000').toISOString();
      }else{
        this.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').toISOString();
      }

      const postData = {
        from: this.from,
        to: this.to,
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        sorting_data: this.sorting_data
      };

      let response;
      if (this.isMasterMerchant) {
        postData.filters.merchants = this.merchants.map(
          (merchant) => merchant.email_id
        );
        response = this.$store.dispatch(
          `payouts/${FETCH_PAYOUT_LIST_MASTER_MERCHANT}`,
          postData
        );
      } else {
        response = this.$store.dispatch(`payouts/${FETCH_PAYOUT_LIST}`, postData);
      }

      this.tableFieldsHideAndShow();
      return response;
    },

    tableFieldsHideAndShow() {
      let selectPayoutClass = "";
      let actionClass = "";
      if(this.currentPageTitle === "statement") {
        selectPayoutClass = "d-none";
      } else {
        actionClass = "d-none";
      }
      if(this.isMasterMerchant) {
        actionClass = "d-none";
      }
      this.fields = this.fields.map((field) => {
        if(field.key === 'selectpayout') {
          field.thClass = selectPayoutClass;
          field.tdClass = selectPayoutClass;
        }
        if(field.key === 'action') {
          field.thClass = actionClass;
          field.tdClass = actionClass;
        }
        return field;
      });
    },

    VerifyPayments() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutVerify.$invalid || this.selectedTransactionRefs.length === 0) {
        return;
      }

      this.verify();

    },
    verify(otpSaved = false) {
      let payload = {
        approveData: {
          otp: this.payoutVerify.otp,
          txn_refs: this.selectedTransactionRefs,
          action: this.verifyAction,
          save_otp: this.payoutVerify.saveOtp
        },
      };

      if(otpSaved) {
          payload.approveData.otp = "";
          payload.approveData.save_otp = false;
        }

      this.showVerify = false;
      this.submitted = false;

      this.$store
        .dispatch(`payouts/${VERIFY_PAYOUTS_VIA_EMAIL}`, payload)
        .then(() => this.refreshRecord())
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
          setTimeout(() => {
            this.resetPayoutVerify();
            this.resetPayoutMobile();
          }, 3000);
          console.log(error);
        })
        .finally(() => {
          this.selectedTransactionRefs = [];
          this.selectAvailableInPage = false;
        });
    },

    ApprovePayments() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutApprove.$invalid || this.selectedTransactionRefs.length === 0) {
        return;
      }
      this.approve();
    },
    approve(otpSaved = false) {
      let payload = {
        approveData: {
          otp: this.payoutApprove.otp,
          txn_refs: this.selectedTransactionRefs,
          action: this.approveAction,
          save_otp: this.payoutApprove.saveOtp
        },
      };

      if(otpSaved) {
        payload.approveData.otp = "";
        payload.approveData.save_otp = false;
      }

      console.log("approveData");
      this.showApprove = false;
      this.submitted = false;

      this.$store
        .dispatch(`payouts/${APPROVE_PAYOUTS_VIA_EMAIL}`, payload)
        .then(() => this.refreshRecord())
        .catch((error) => {
          const err = JSON.parse(error.message);
          this.$notify({
            type: "error",
            text: err.message,
            closeOnClick: true,
          });
          setTimeout(() => {
            this.resetPayoutApprove();
            this.resetPayoutMobile();
          }, 3000);
          console.log(error);
        })
        .finally(() => {
          this.selectedTransactionRefs = [];
          this.selectAvailableInPage = false;
        });
    },

    GenerateOtp() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.payoutMobile.$invalid) {
        return;
      }
      this.$store.dispatch(
        `payouts/${GENERATE_OTP_PAYOUT}`,
        this.payoutMobile.mobileNo
      );

      this.submitted = false;
      this.enterMobile = false;
      this.resetPayoutApprove();
      this.resetPayoutVerify();
      this.resetPayoutMobile().catch((error) => {
        setTimeout(() => {
          this.resetPayoutCreation();
        }, 3000);
        console.log(error);
      });
    },

    resendOTP() {
      this.$store
        .dispatch(`payouts/${RESEND_OTP_PAYOUT_VIA_EMAIL}`)
        .then((result) => {
          console.log("Resend OTP successfully", result);
        })
        .catch((error) => {
          console.log("Failed to resend OTP", error);
        });
    },

    resendOTPForVerify() {
      this.$store
        .dispatch(`payouts/${RESEND_OTP_PAYOUT_VIA_EMAIL_FOR_VERIFY}`)
        .then((result) => {
          console.log("Resend OTP successfully", result);
        })
        .catch((error) => {
          console.log("Failed to resend OTP", error);
        });
    },

    fileUpload() {
        let file = this.$refs.fileupload.files[0];
        if(Common.excelFileValdation(file, 50, true, /(\.xlsx)$/i)) {
            this.uploadedFile = file;
            this.fileUploadSuccess = true;
        }
    },

    // fileUpload(fieldName, fileList) {
    //   let isValid = Common.excelFileValdation(fileList[0]);
    //   if (isValid) {
    //     this.filePath = fileList;
    //     this.fileUploadSuccess = true;
    //   } else {
    //     this.$refs.fileupload.value = "";
    //     Swal.fire(
    //       "error",
    //       `Maximum 20 MB file is allowed and xlsx files are allowed`
    //     );
    //   }
    // },
      removeSelectedFile() {
          this.uploadedFile = null;
          this.$refs.fileupload.value = null;
      },
      fileUploads() {
        if(this.selectedChannel === 'PAYNAMICS_PAYOUT') {
          return;
        }
        const formData = new FormData();
        if (!this.uploadedFile) return;
        formData.append("file", this.$refs.fileupload.files[0]);
        formData.append("key", this.$store.state.auth.currentUser.iamportKey);
        formData.append("channel_key", this.selectedChannel);
        if(this.selectedChannel == 'GBPRIMEPAY_PAYOUT') {
          formData.append("split_payouts", this.split_payouts);
        }
        // this.loading = true;
        this.$store.dispatch(`payouts/${PAYOUT_FILE_UPLOAD}`, {fileData: formData,})
      .then(() => {
        this.fileUploadSuccess = false;
        this.selectedChannel = "";
        this.removeSelectedFile();
        this.hideUploadModal();
        setTimeout(() => {
          this.refreshRecord();
        }, 500);
        this.$notify({
          type: "success",
          text: this.$t("common.file_upload_success"),
          closeOnClick: true,
        });
      })
      .catch((error) => {
          // error
          this.hideUploadModal();
          console.log("error", error);
          this.$notify({
            type: "error",
            text: JSON.parse(error.message).message,
            closeOnClick: true,
          });
        });
    },
    handleOtpChange(value) {
      this.payoutApprove.otp = value;
    },

    handlePayoutOtpChange(value) {
      this.payoutVerify.otp = value;
    },

    filterApplied() {
      return Boolean(
        this.filters.approved_status ||
          this.filters.verified_status ||
          (this.filters.status && this.filters.status.length)
      );
    },

    deletePayout(txn_ref) {
      this.$store
        .dispatch(`payouts/${UPDATE_PAYOUT_STATUS}`, txn_ref)
        .then(() => {
          this.refreshRecord();
        });
    },

    editPayout(item) {
      this.editItem = item;
      this.isEdit = true;
      this.$router.push({ name: 'Update Payout', query: { ref: item.txn_ref } })
    },

    resetPayoutApprove() {
      this.payoutApprove = {
        otp: "",
        saveOtp: false
      };
    },

    resetPayoutVerify() {
      this.payoutVerify = {
        otp: "",
        saveOtp: false
      };
    },

    resetAdvanceFilters() {
      this.advancedFilterClosed = !this.advancedFilterClosed;
      this.filters = {
        approved_status: "",
        status: [],
        verified_status: "",
      };
      if (this.advancedFilterClosed) {
        this.refreshRecord();
      }
      // this.$store.dispatch(`transactions/${FETCH_TRANSACTIONS}`, {pageIndex: 1, pagesize: 10, filters: this.filters})
    },

    sortTransactions(item) {

    this.sorting_data.sort_by_key = item.sortBy;

      if(item.sortDesc) {
        this.sorting_data.order = "DESC";
      } else {
        this.sorting_data.order = "ASC";
      }
      this.refreshRecord();
    },

    resetPayoutMobile() {
      this.payoutMobile = {
        mobileNo: "",
      };
    },
    exportTransactionIntoExcel() {
      if(this.toDate) {
        this.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').toISOString();
      }else{
        this.to = new Date().toISOString()
      }

      if(!this.fromDate) {
        this.from = moment('2018-01-01T00:00:00.000').toISOString();
      }else{
        this.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').toISOString();
      }

      const postData = {
        from: this.from,
        to: this.to,
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        sorting_data: this.sorting_data
      };
      if (this.isMasterMerchant) {
        postData.merchant_keys = this.merchants.map(
          (merchant) => merchant.key
        );
      }
      this.$store.dispatch(`payouts/${EXPORT_PAYOUTS_INTO_EXCEL}`, postData);
    },

    // disabledAfterEndDate(date) {
    //   if(this.to) {
    //     return date > new Date(this.to) || date > new Date();
    //   }
    //   return date > new Date();
    // },

    // disabledBeforeStartDate(date) {
    //   if(this.from) {
    //     return date < new Date(this.from) || date > new Date();
    //   }
    //   return date > new Date();
    // },
    tabClick(val){
      this.activeTab = val
      if(val == 'payout' ){
        this.getStatementData();
        this.createPayoutToShow = true;
      }else if(val == 'verify_payout' ){
        this.getNotVerifiedData();
        this.createPayoutToShow = false;
      }else if(val == 'approve_payout' ){
        this.getNotApprovedData();
        this.createPayoutToShow = false;
      }
    },
    dateChanged(value) {
      this.fromDate = value[0];
      this.toDate = value[1];

      this.refreshRecord();
    },
    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'payouts-filter-collapse');
    },
    closeViewDetailsModal() {
      this.viewDetailsModalOpen = false;
    },
    viewDetailsClick(data){
      this.viewDetailsData = data
      this.viewDetailsModalOpen = true
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>



<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->

    <div class="row justify-content-end mb-3">
      <multiselect
        v-if="isMasterMerchant"
        v-model="merchants"
        :options="merchantListDataFromStore"
        @input="refreshRecord"
        placeholder="Search Merchants"
        track-by="email_id"
        label="email_id"
        class="sm-multiselect col-md-3"
        :searchable="true"
        :multiple="true"
        :taggable="true"
      ></multiselect>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="pt-0">
            <div class="menu-content">
              <div class="tab-content-section">
                  <b-btn variant="primary"  @click="tabClick('payout')" class="tab-button" :class="{'tab-active': activeTab === 'payout'}">
                    {{ $t("views.payouts.transaction") }}
                  </b-btn>
                  <b-btn variant="primary"  @click="tabClick('verify_payout')" class="tab-button" :class="{'tab-active': activeTab === 'verify_payout'}"
                    v-if="isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled && !isMasterMerchant">
                     {{ $t("views.payouts.verif-title") }}
                  </b-btn>
                  <b-btn variant="primary"  @click="tabClick('approve_payout')" class="tab-button" :class="{'tab-active': activeTab === 'approve_payout'}"
                    v-if="!isMasterMerchant">
                     {{ $t("views.payouts.approve-title") }}
                  </b-btn>
              </div>
              <router-link v-if="!isMasterMerchant" :to="'/payouts/manage-payouts/create-payout'">
                <button
                    v-if="userPermissions.includes('create-payouts') && createPayoutToShow"
                    class="btn btn-primary btn-sm create-payout-button"
                    style="position: absolute;right: 10px;"
                  >
                  <span style="padding-right: 10px;">+</span>{{ $t("views.payouts.create-payouts") }}
                </button>
              </router-link>
            </div>
            <div v-if="activeTab === 'payout'">
              <div @click="openUploadModal()" v-if="!isMasterMerchant" style="display: flex;align-items: center;justify-content: center;padding: 14px;border-radius: 12px;border: 2px #DCDAD5 dotted;cursor: pointer;">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <circle cx="20" cy="20" r="20" fill="#DCDAD5" fill-opacity="0.3"/>
                    <path d="M23.6997 17.4167C26.6997 17.6751 27.9247 19.2167 27.9247 22.5917V22.7001C27.9247 26.4251 26.4331 27.9167 22.7081 27.9167H17.2831C13.5581 27.9167 12.0664 26.4251 12.0664 22.7001V22.5917C12.0664 19.2417 13.2747 17.7001 16.2247 17.4251" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M20 22.4999V13.0166" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.7923 14.8749L20.0007 12.0833L17.209 14.8749" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <div style="margin-left: 16px;">
                  <div style="font-size: 14px;line-height: 20px;">{{ $t("views.payouts.upload_payout") }}</div>
                  <div style="color: rgba(0, 0, 0, 0.30);font-size: 12px;font-weight: 325;line-height: 16px;margin-top: 2px;">Click here to upload payouts, allowed file type: csv</div>
                </div>
              </div>
              <div v-if="currentPageTitle == 'statement'">
                <div class="row mt-4 d-flex justify-content-between">
                  <div class="col-sm-12 col-md-12">
                    <!-- <div @click="resetAdvanceFilters()">
                      <FilterBtn
                        :isActive="filterApplied()"
                        v-b-toggle.payouts-filter-collapse
                      />
                    </div> -->
                    <filters class="mb-3" @date-change="dateChanged" @toggle-filter="toggleFilter" />
                  </div>
                  <!-- <div class="col-sm-12 col-md-6 d-flex justify-content-end">
                    <div style="width: 70% !important">
                      <b-input-group>
                        <b-form-input
                          type="search"
                          v-model="mor_filter"
                          @keyup="refreshRecord"
                          @keyup.enter="refreshRecord"
                          class="form-control-sm"
                          placeholder="Search for references or customer details"
                        ></b-form-input>
                        <b-input-group-append>
                          <button
                            class="btn btn-primary btn-block btn-sm"
                            @click="refreshRecord"
                          >
                            {{ $t("button.search") }}
                          </button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="down-btn" @click="exportTransactionIntoExcel()">
                      <downloadBtn />
                    </div>
                  </div> -->
                  <!-- Custom Search End-->
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <b-collapse id="payouts-filter-collapse">
                      <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
                        <div class="row">
                          <!-- <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                            <label for="startdate" style="display: block;">{{ $t('common.start_date') }}</label>
                            <date-picker
                              type="datetime"
                              :placeholder="$t('common.start_date')"
                              format="DD-MM-YYYY HH:mm"
                              v-model="from"
                              name="startdate"
                              class="custom-datepicker w-100"
                              prefix-class="xmx"
                              :disabled-date="disabledAfterEndDate"
                            ></date-picker>
                          </div> -->
                          <!-- <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                            <label for="enddate" style="display: block;">{{ $t('common.end_date') }}</label>
                            <date-picker
                              type="datetime"
                              :placeholder="$t('common.end_date')"
                              v-model="to"
                              format="DD-MM-YYYY HH:mm"
                              name="enddate"
                              class="custom-datepicker w-100"
                              prefix-class="xmx"
                              :disabled-date="disabledBeforeStartDate"
                            ></date-picker>
                          </div> -->
                        </div>
                        <div class="row mt-3">
                          <div class="col-sm-12 col-md-4">
                            <multiselect
                              v-model="filters.status"
                              :options="payoutFilterStatus"
                              placeholder="Select Payout Status"
                              class="sm-multiselect"
                              :searchable="false"
                              :multiple="true"
                              :taggable="true"
                            ></multiselect>
                          </div>
                          <div class="col-sm-12 col-md-4">
                            <multiselect
                              v-model="filters.approved_status"
                              :options="payoutApprovalFilterStatus"
                              :placeholder="
                                $t('views.payouts.select_approval_status')
                              "
                              class="sm-multiselect"
                              :searchable="false"
                              :multiple="false"
                              :taggable="true"
                            ></multiselect>
                          </div>
                          <div
                            class="col-sm-12 col-md-4"
                            v-if="
                              isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled ||
                              isMasterMerchant
                            "
                          >
                            <multiselect
                              v-model="filters.verified_status"
                              :options="payoutVerifyFilterStatus"
                              :placeholder="
                                $t('views.payouts.select_verify_status')
                              "
                              class="sm-multiselect"
                              :searchable="false"
                              :multiple="false"
                              :taggable="true"
                            ></multiselect>
                          </div>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                          <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
                          <div>
                            <b-btn variant="primary" @click="toggleFilter"  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                            <b-btn variant="primary" class="btn-padding" @click="refreshRecord">Apply</b-btn>
                          </div>
                        </div>


                        <!-- <div class="col-sm-12 col-md-6 col-xl-3 ml-auto">
                            <div class="row">
                              <div class="col-sm-12 col-md-6">
                                <b-button
                                  variant="primary"
                                  size="sm"
                                  style="height: 35px"
                                  class="btn btn-block"
                                  @click="refreshRecord"
                                  >{{ $t("button.apply") }}</b-button
                                >
                              </div>
                              <div class="col-sm-12 col-md-6">
                                <b-button
                                  variant="light"
                                  size="sm"
                                  style="height: 35px"
                                  class="btn btn-block"
                                  @click="resetAdvanceFilters()"
                                  v-b-toggle.payouts-filter-collapse
                                  >{{ $t("button.clear") }}</b-button
                                >
                              </div>
                            </div>
                          </div> -->
                      </b-card>
                    </b-collapse>
                    <div class="d-flex justify-content-between mb-3">
                      <!-- :options="searchByOptions" -->
                      <search-by v-model="searchBy" @search-by-clicked="refreshRecord" :hideSearchButton="true" />
                      <download-btn @download-button-clicked="exportTransactionIntoExcel()" />
                    </div>
                  </div>
                </div>

                <div class="">
                  <b-table
                    class="table-centered text-center table-custom statement-table"
                    :items="payoutsDataFromStore"
                    :fields="fields"
                    responsive="sm"
                    @sort-changed="sortTransactions"
                    :bordered="false"
                    sticky-header="50vh"
                    show-empty
                    :empty-text="$t('common.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:cell(amount)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(email_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(sms_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(due_date)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(approved_time)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(approved_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Approved',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(email_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(sms_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(verified_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Verified',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'Not Applicable',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': `${row.value}` === 'Success',
                          'badge-soft-warning':
                            `${row.value}` === 'Initiated' ||
                            `${row.value}` === 'Created',
                          'badge-soft-pending':
                            `${row.value}` === 'Pending',
                          'badge-soft-danger':
                            `${row.value}` === 'Failed' ||
                            `${row.value}` === 'Cancelled' ||
                            `${row.value}` === 'Rejected',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <!-- <template v-slot:cell(action)="{ item }">
                      <b-btn
                        variant="light"
                        size="sm"
                        class="mr-2"
                        v-if="
                          item.status === 'Created' &&
                          item.approved_status !== 'Approved' &&
                          item.verified_status !== 'Verified'
                        "
                        @click="editPayout(item)"
                        >Edit</b-btn
                      >
                      <b-btn
                        variant="danger"
                        size="sm"
                        v-if="
                          item.status === 'Created' &&
                          item.approved_status !== 'Approved' &&
                          item.verified_status !== 'Verified'
                        "
                        @click="deletePayout(item.txn_ref)"
                        >Cancel</b-btn
                      >
                    </template> -->


                    <template v-slot:cell(action)="{ item }">
                      <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;justify-content: flex-end;">
                          <div v-if="item.status === 'Created' && item.approved_status !== 'Approved' && item.verified_status !== 'Verified'" @click="editPayout(item)" style="padding: 10px 10px;border-radius: 8px;background: rgba(220, 218, 213, 0.30); height: 36px;">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <g id="vuesax/linear/edit-2" opacity="0.3">
                                    <g id="edit-2">
                                    <path id="Vector" d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_2" d="M7.92578 3.3667C8.21245 5.2067 9.70578 6.61337 11.5591 6.80003" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path id="Vector_3" d="M2 14.6665H14" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                  </g>
                                </svg>
                          </div>
                          <b-dropdown class="show_details_payout" size="sm" variant="white" dropleft>
                              <template v-slot:button-content>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g id="Iconsax/Linear/more">
                                    <path id="Vector" d="M6.6665 12.6667C6.6665 13.4 7.2665 14 7.99984 14C8.73317 14 9.33317 13.4 9.33317 12.6667C9.33317 11.9333 8.73317 11.3333 7.99984 11.3333C7.2665 11.3333 6.6665 11.9333 6.6665 12.6667ZM6.6665 3.33333C6.6665 4.06667 7.2665 4.66667 7.99984 4.66667C8.73317 4.66667 9.33317 4.06667 9.33317 3.33333C9.33317 2.6 8.73317 2 7.99984 2C7.2665 2 6.6665 2.6 6.6665 3.33333ZM6.6665 8C6.6665 8.73333 7.2665 9.33333 7.99984 9.33333C8.73317 9.33333 9.33317 8.73333 9.33317 8C9.33317 7.26667 8.73317 6.66667 7.99984 6.66667C7.2665 6.66667 6.6665 7.26667 6.6665 8Z" stroke="black" stroke-opacity="0.3"/>
                                    </g>
                                </svg>
                              </template>
                              <b-dropdown-item-button @click="viewDetailsClick(item)" >{{ $t('views.payouts.view-details') }}</b-dropdown-item-button>
                              <b-dropdown-item-button v-if=" item.status === 'Created' && item.approved_status !== 'Approved' && item.verified_status !== 'Verified'" @click="deletePayout(item.txn_ref)">{{ $t("button.delete") }}</b-dropdown-item-button>
                              <!-- <b-dropdown-item-button  @click="deletePaymentLink(row.item.link_ref)">Delete Invoice</b-dropdown-item-button> -->
                          </b-dropdown>
                      </div>
                  </template>




                    <template v-slot:head()="data">
                      <span
                        v-b-tooltip.hover.top
                        :title="$t(data.field.tooltip)"
                      >
                        {{ $t(data.label) }}
                      </span>
                    </template>
                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                    <div class="d-flex justify-content-between mb-3">
                    <!-- pagination -->
                      <per-page
                        v-model="perPage"
                        :currentPage="currentPage"
                        :totalRows="rows"
                        :tableRowsCount="payoutsDataFromStore.length"
                        @input="refreshRecord"
                      />
                      <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="refreshRecord" ></b-pagination>
                  </div>
                </div>
                <div class="right-bar"
                  style=""
                  :class="{
                      'active': showUploadmodal
                  }">
                  <!-- <img src="@/assets/images/update_config_bottom.png" class="right-bottom-bg-image" alt /> -->
                  <div class="h-100 d-flex flex-column">
                      <div class="pt-4 px-4 ml-2 d-flex justify-content-between">
                      <div class="pt-3 mt-1">
                          <p style="font-size: 18px; font-weight: 400; line-height: 24px;" class="mb-1">
                              Upload Payouts
                          </p>
                          <p class="section-text" style="line-height: 16px;">Upload your files here to get started.</p>
                      </div>
                      <i class="mdi mdi-close noti-icon cursor-pointer mb-auto" style="position: relative;z-index: 1; font-size: 20px;" @click="hideUploadModal"></i>
                      </div>

                      <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;">
                      <div class="px-4 ml-2">
                          <label>Payout Channel</label>
                          <multiselect
                            v-model="selectedChannel"
                            :options="paymentChannelCredsList"
                            placeholder="Select Payout Channel"
                            :searchable="false"
                            :multiple="false"
                          ></multiselect>

                          <div
                            style="margin-top: 15px;color: rgba(0, 0, 0, 0.5);font-size: 12px;line-height: 16px;font-weight: 325;"
                            v-if="selectedChannel === 'PAYNAMICS_PAYOUT'"
                          >
                            Bulk Payout creation is currently not available for Paynamics Payout channel
                          </div>
                        <div
                            style="margin-top: 15px;color: rgba(0, 0, 0, 0.5);font-size: 12px;line-height: 16px;font-weight: 325;"
                            v-if="paymentChannelCredsList.length == 0"
                          >
                            Please configure a Payout Channel at
                            <span class="text-chai" style="cursor: pointer;" @click="$router.push({name: 'Payment Channels', query: { tab: 'Payout Channels' }})"
                              >Payout Channels section</span>
                            or Kindly contact business team of PortOne
                            for more details.
                          </div>

                          <div class="position-relative file-input-draggable" style="margin-bottom: 12px;margin-top: 15px;">
                              <input :disabled="!selectedChannel || selectedChannel === 'PAYNAMICS_PAYOUT'" type="file" name="UploadFiles" accept=".xlsx" ref="fileupload" @change="fileUpload" class="file_upload">
                              <div class="d-flex flex-column align-items-center" v-if="uploadedFile">
                                  <div style="border-radius: var(--8, 8px); background: #FFF; box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06); margin-top: 32px; margin-bottom: 12px;" class="p-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path d="M15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048Z" fill="#FC6B2D"/>
                                          <path d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM11.5 17.75H7.5C7.09 17.75 6.75 17.41 6.75 17C6.75 16.59 7.09 16.25 7.5 16.25H11.5C11.91 16.25 12.25 16.59 12.25 17C12.25 17.41 11.91 17.75 11.5 17.75ZM13.5 13.75H7.5C7.09 13.75 6.75 13.41 6.75 13C6.75 12.59 7.09 12.25 7.5 12.25H13.5C13.91 12.25 14.25 12.59 14.25 13C14.25 13.41 13.91 13.75 13.5 13.75Z" fill="#FC6B2D"/>
                                      </svg>
                                  </div>
                                  <div class="d-flex" style="margin-bottom: 12px;">
                                      <p class="font-14px mb-0" style="line-height: 20px; margin-bottom: 4px; max-width: 200px; overflow: hidden; text-overflow: ellipsis;">{{ uploadedFile.name }}</p>
                                      <!-- <span class="my-auto" @click="removeSelectedFile">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="margin-left: 12px;">
                                              <path d="M14 3.98732C11.78 3.76732 9.54667 3.65398 7.32 3.65398C6 3.65398 4.68 3.72065 3.36 3.85398L2 3.98732M5.66667 3.31398L5.81333 2.44065C5.92 1.80732 6 1.33398 7.12667 1.33398H8.87333C10 1.33398 10.0867 1.83398 10.1867 2.44732L10.3333 3.31398M12.5667 6.09398L12.1333 12.8073C12.06 13.854 12 14.6673 10.14 14.6673H5.86C4 14.6673 3.94 13.854 3.86667 12.8073L3.43333 6.09398M6.88667 11.0007H9.10667M6.33333 8.33398H9.66667" stroke="#DE3535" stroke-linecap="round" stroke-linejoin="round"/>
                                          </svg>
                                      </span> -->
                                  </div>
                                  <p class="font-14px" style="line-height: 16px; margin-bottom: 12px;">or</p>
                                  <span class="font-14px text-white" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #000; padding: 14px 16px; color: #FFF; margin-bottom: 32px;">
                                      Replace File
                                  </span>
                              </div>
                              <div :class="{'upload-disabled': !selectedChannel}" class="d-flex flex-column align-items-center" v-else>
                                  <div style="border-radius: var(--8, 8px); background: #FFF; box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06); margin-top: 32px; margin-bottom: 12px;" class="p-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="m-1">
                                          <g clip-path="url(#clip0_3027_37991)">
                                              <path d="M10.959 5.93268C13.359 6.13935 14.339 7.37268 14.339 10.0727V10.1593C14.339 13.1393 13.1457 14.3327 10.1657 14.3327H5.82568C2.84568 14.3327 1.65234 13.1393 1.65234 10.1593V10.0727C1.65234 7.39268 2.61901 6.15935 4.97901 5.93935M7.99901 9.99935V2.41268M10.2323 3.89935L7.99901 1.66602L5.76568 3.89935" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                          </g>
                                          <defs>
                                              <clipPath id="clip0_3027_37991">
                                              <rect width="16" height="16" fill="white"/>
                                              </clipPath>
                                          </defs>
                                      </svg>
                                  </div>
                                  <p class="font-14px mb-0" style="line-height: 20px; margin-bottom: 4px;">Drag & Drop your file here</p>
                                  <p style="color: rgba(0, 0, 0, 0.30); font-size: 10px; line-height: 12px; margin-bottom: 24px;">Maximum file size: 50 Mb | File format: XLSX</p>
                                  <span class="font-14px text-white" style="border-radius: 12px; border: 1px solid #DCDAD5; background: #000; padding: 14px 16px; color: #FFF; margin-bottom: 32px;">
                                      Browse File
                                  </span>
                              </div>
                          </div>
                          <b-form-checkbox
                            class="my-3"
                            v-if="selectedChannel == 'GBPRIMEPAY_PAYOUT'"
                            v-model="split_payouts"
                            :value="true"
                            :unchecked-value="false"
                          >
                            Automatically split payouts with amount greater than THB 700,000
                          </b-form-checkbox>
                      </div>
                      <div class="mt-auto mb-4">
                          <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;">
                          <div class="d-flex justify-content-start px-3">
                              <b-button variant="white" @click="hideUploadModal" class="mr-2 bg-white text-dark border-dark cancel">{{ $t('button.cancel') }}</b-button>
                              <b-button variant="primary" :disabled="!fileUploadSuccess || !selectedChannel || selectedChannel === 'PAYNAMICS_PAYOUT'" @click="fileUploads" class="btn-padding" >{{ $t('button.submit') }}</b-button>
                          </div>
                      </div>
                  </div>
                  <!-- end slimscroll-menu-->
                  </div>
                  <!-- /Right-bar -->
                  <!-- Right bar overlay-->
                  <div @click="hideUploadModal" class="rightbar-overlay"
                    :class="{
                        'active': showUploadmodal
                    }">
                  </div>
                   <div
                    class="right-bar"
                    v-if="viewDetailsModalOpen"
                    :class="{
                      active: viewDetailsModalOpen,
                    }"
                  >
                    <div data-simplebar class="h-100" style="overflow-x: auto">
                      <div class="rightbar-title">
                        <div class="right-top-section">
                          <div class="card-body-custom">
                            <i
                              class="mdi mdi-close noti-icon float-right cursor-pointer"
                              style="
                                position: relative;
                                z-index: 1;
                                right: 25px;
                                top: 25px;
                                font-size: 20px;
                              "
                              @click="closeViewDetailsModal"
                            ></i>
                            <div style="padding-left: 30px; padding-bottom: 25px; padding-right: 30px;" class="media-body media-body-custom">
                              <p
                                class="d-inline-block"
                                style="font-size: 18px; margin-top: 45px; margin-bottom: 0px; line-height: 24px;"
                              >
                                Payout Details
                              </p>
                              <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;line-height: 16px;margin-top: 2px;">Check details of the selected payout.</div>
                            </div>
                            <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>
                          </div>
                        </div>
                      </div>
                      <div class="plan-view-details" style="padding-left: 30px;padding-right: 30px;">
                        <!-- {{viewDetailsData}} -->
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Transaction Reference</div>
                          <div class="plan-view-value text-chai">{{viewDetailsData.merchant_txn_ref}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Account Name</div>
                          <div class="plan-view-value">{{viewDetailsData.account_name}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Currency</div>
                          <div class="plan-view-value">{{viewDetailsData.currency}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Amount</div>
                          <div class="plan-view-value">{{viewDetailsData.amount}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Payout Status</div>
                          <div class="plan-view-value">
                            <div
                              class="badge font-size-12"
                              style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                              :class="{
                                'badge-soft-success': `${viewDetailsData.status}` === 'Success',
                                'badge-soft-warning':
                                  `${viewDetailsData.status}` === 'Initiated' ||
                                  `${viewDetailsData.status}` === 'Created',
                                'badge-soft-pending':
                                  `${viewDetailsData.status}` === 'Pending',
                                'badge-soft-danger':
                                  `${viewDetailsData.status}` === 'Failed' ||
                                  `${viewDetailsData.status}` === 'Cancelled' ||
                                  `${viewDetailsData.status}` === 'Rejected',
                              }"
                            >
                              {{ viewDetailsData.status }}
                            </div>                            
                          </div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Bank Name</div>
                          <div class="plan-view-value">{{viewDetailsData.bank_name}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Beneficiary Account Number</div>
                          <div class="plan-view-value">{{viewDetailsData.to_account_no}}</div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="plan-view-section">
                              <div class="plan-view-heading">Sent Email Status</div>
                              <div class="plan-view-value"
                                :class="{
                                  'sms-email-success': viewDetailsData.email_sent === 'Sent',
                                  'sms-email-danger': viewDetailsData.email_sent === 'Failed' || viewDetailsData.email_sent === 'Not Sent',
                                  'sms-email-warning': viewDetailsData.email_sent === 'Waiting',
                                  'sms-email-secondary':
                                    viewDetailsData.email_sent === '' ||
                                    viewDetailsData.email_sent === 'N/A',
                                }"
                              >{{viewDetailsData.email_sent ? viewDetailsData.email_sent : "-"}}</div>
                            </div>
                            <div class="plan-view-section">
                              <div class="plan-view-heading">Sent SMS Status</div>
                              <div class="plan-view-value"
                                :class="{
                                  'sms-email-success': viewDetailsData.sms_sent === 'Sent',
                                  'sms-email-danger': viewDetailsData.sms_sent === 'Failed' || viewDetailsData.sms_sent === 'Not Sent',
                                  'sms-email-warning': viewDetailsData.sms_sent === 'Waiting',
                                  'sms-email-secondary':
                                    viewDetailsData.sms_sent === '' ||
                                    viewDetailsData.sms_sent === 'N/A',
                                }"
                              >{{viewDetailsData.sms_sent ? viewDetailsData.sms_sent : "-" }}</div>
                            </div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Virtual Account Number</div>
                          <div class="plan-view-value">{{viewDetailsData.vaaccount_no}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Payout Approval Status</div>
                          <div
                            class="badge font-size-12"
                            style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;margin-top: 5px;"
                            :class="{
                              'badge-soft-success': viewDetailsData.approved_status === 'Approved',
                              'badge-soft-danger': viewDetailsData.approved_status === 'Rejected' || viewDetailsData.approved_status === 'Cancelled',
                              'badge-soft-pending': viewDetailsData.approved_status === 'Pending',
                            }"
                          >
                            <svg v-if="viewDetailsData.approved_status == 'Pending'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g id="Iconsax/Linear/refresh" clip-path="url(#clip0_2_111124)">
                                <path id="Vector" d="M14.6673 8.00016C14.6673 11.6802 11.6807 14.6668 8.00065 14.6668C4.32065 14.6668 2.07398 10.9602 2.07398 10.9602M2.07398 10.9602H5.08732M2.07398 10.9602V14.2935M1.33398 8.00016C1.33398 4.32016 4.29398 1.3335 8.00065 1.3335C12.4473 1.3335 14.6673 5.04016 14.6673 5.04016M14.6673 5.04016V1.70683M14.6673 5.04016H11.7073" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_2_111124">
                                  <rect width="16" height="16" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                            <svg v-if="viewDetailsData.approved_status == 'Approved'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <g id="Group">
                                <g id="Group_2">
                                  <path id="Vector" d="M4.54115 6.73066C4.31236 6.4986 3.93977 6.4986 3.70772 6.72739C3.47567 6.95617 3.47567 7.32876 3.70445 7.56082L6.49562 10.3716C6.60674 10.4827 6.75382 10.5448 6.9107 10.5448C6.9107 10.5448 6.9107 10.5448 6.91397 10.5448C7.07085 10.5448 7.21793 10.4827 7.32905 10.3749L15.8268 1.92292C16.0555 1.69413 16.0588 1.32154 15.83 1.08949C15.6012 0.857437 15.2286 0.857437 14.9966 1.08622L11.993 4.07022C10.7151 2.49815 8.79327 1.57647 6.75055 1.57647C5.79619 1.57647 4.87452 1.77257 4.01168 2.15497C3.17825 2.52756 2.43633 3.05377 1.81208 3.72705C1.18782 4.39706 0.713913 5.17166 0.40342 6.02796C0.0798535 6.92022 -0.0508804 7.85824 0.0177549 8.81913C0.249808 12.143 2.93312 14.8296 6.25703 15.0682C6.42045 15.0813 6.58713 15.0846 6.75055 15.0846C8.29648 15.0846 9.79012 14.5616 10.9929 13.5877C12.3067 12.5287 13.1696 11.0285 13.4278 9.36821C13.4801 9.02831 13.5062 8.67859 13.5062 8.32888C13.5062 8.00531 13.2415 7.74058 12.9179 7.74058C12.5944 7.74058 12.3296 8.00531 12.3296 8.32888C12.3296 8.61649 12.3067 8.90411 12.2643 9.18845C11.8263 12.0319 9.22469 14.0975 6.34201 13.8916C3.59986 13.6955 1.38392 11.4796 1.19109 8.73415C1.07997 7.16535 1.60617 5.67171 2.67165 4.52779C3.72406 3.40021 5.21116 2.75308 6.75055 2.75308C8.47624 2.75308 10.0973 3.55382 11.153 4.90692L6.91724 9.12309L4.54115 6.73066Z" fill="#219653"/>
                                </g>
                              </g>
                          </svg>
                           <span v-if="viewDetailsData.approved_status === 'Approved' || viewDetailsData.approved_status === 'Pending'" style="padding-left: 5px;color: #000;"> {{ viewDetailsData.approved_status ? viewDetailsData.approved_status : "-" }} </span>
                           <span v-else > {{ viewDetailsData.approved_status ? viewDetailsData.approved_status : "-" }} </span>
                          </div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Approved By</div>
                          <div class="plan-view-value">{{viewDetailsData.approved_by ? viewDetailsData.approved_by : "-"}}</div>
                        </div>
                        <div class="plan-view-section">
                          <div class="plan-view-heading">Approval Date & Time</div>
                          <div class="plan-view-value">{{viewDetailsData.approved_time ? getFormattedDate(viewDetailsData.approved_time) : "-"}}</div>
                        </div>
                        <!-- <div class="plan-view-section">
                          <div class="plan-view-heading">Transaction Response</div>
                          <div class="plan-view-value">{{viewDetailsData.address}}</div>
                        </div> -->
                        <!-- <div class="plan-view-section">
                          <div class="plan-view-heading">PortOne Transaction Reference</div>
                          <div class="plan-view-value">{{viewDetailsData.address}}</div>
                        </div> -->
                      </div>
                    </div>
                    <!-- end slimscroll-menu-->
                  </div>
                  <!-- Right bar overlay-->
                  <div
                    @click="closeViewDetailsModal"
                    class="rightbar-overlay"
                    :class="{
                      active: viewDetailsModalOpen,
                    }"
                  ></div>
              </div>
            </div>
            <div v-if="isVerifyPayoutsEnabledData.isVerifyPayoutsEnabled && !isMasterMerchant && activeTab === 'verify_payout'">
              <div v-if="currentPageTitle == 'not-verified'">
                <div class="d-flex" style="align-items: center; font-size: 14px;padding: 16px 24px;color: rgba(0, 0, 0, 0.70);font-weight: 325;background: #FFFAEA;border-radius: 12px;border: 1px #FEF2CF solid;margin-bottom: 25px;"
                v-if="!$store.state.auth.currentUser.is_2fa_enabled">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="vuesax/linear/info-circle"><g id="info-circle">
                      <path id="Vector" d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Vector_2" d="M12 8V13" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Vector_3" d="M11.9941 16H12.0031" stroke="#FC6B2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g></g>
                  </svg>
                  <span style="margin-left: 16px;">{{ $t("views.payouts.2fa_message") }} <router-link to="/payouts/payout-settings"><span style="text-decoration: underline;font-weight: 350;" class="text-chai">{{ $t("views.payouts.click_here") }}</span></router-link> {{ $t("views.payouts.2fa_message_end") }}</span>
                </div>
                <div class="mb-3">
                  <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="d-flex justify-content-between">
                    <!-- :options="searchByOptions" -->
                      <search-by v-model="searchBy" @search-by-clicked="refreshRecord" :hideSearchButton="true" />
                      <div class="d-flex">
                        <b-btn
                          v-if="userPermissions.includes('verify-payouts')"
                          variant="primary" 
                          class="cancel bg-white text-dark"
                          @click="verifyModal('reject')"
                          :disabled="selectedTransactionRefs.length === 0"
                          style="0px 16px"
                        >
                          Reject
                        </b-btn>
                          <button
                          v-if="userPermissions.includes('verify-payouts')"
                          class="btn btn-primary ml-2"
                          @click="verifyModal('approve')"
                          :disabled="selectedTransactionRefs.length === 0"
                          style="0px 16px"
                        >
                          {{ $t("views.payouts.verify") }}
                          </button>
                      </div>
                    </div>
                  </div>
                  </div>
                  <!-- <b-alert
                    variant="warning"
                    class="text-center mx-auto"
                  >
                    {{selectAllTransactions ? `All ${payoutTotalElementsFromStore}` : selectedTransactionRefs.length }} Transactions are selected.
                    <b-btn variant="link" size="sm" v-if="!selectAllTransactions" class="p-0 text-info" @click="selectAllTxn">select all {{ payoutTotalElementsFromStore }} Transactions.</b-btn>
                  </b-alert> -->
                  <div class="mt-4" style="font-size: 12px;color: rgba(0, 0, 0, 0.50);" v-if="selectedTransactionRefs.length > 0">
                    Selected <span class="text-chai">{{selectAllTransactions ? payoutTotalElementsFromStore : selectedTransactionRefs.length}} {{selectedTransactionRefs.length > 1 ? `Rows` : `Row` }}</span> out of {{ payoutTotalElementsFromStore }}. <span @click="selectAllTxn" style="text-decoration: underline;" class="text-chai cursor-pointer">SELECT</span> all at once here.
                  </div>
                </div>
                <div class="">
                  <b-table
                    class="table-centered text-center table-custom app-very-table"
                    :items="payoutsDataFromStore"
                    :fields="fields"
                    responsive="sm"
                    :bordered="false"
                    sticky-header="50vh"
                    show-empty
                    :empty-text="$t('common.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:cell(selectpayout)="row">
                      <b-form-checkbox
                        :id="`customercheck${row.item.txn_ref}`"
                        :name="`customercheck${row.item.txn_ref}`"
                        :disabled="
                          row.item.verified_status === 'Verified' ||
                          row.item.status != 'Created'
                        "
                        :checked="selectedTransactionRefs.includes(row.item.txn_ref)"
                        @change="toggleSelectPayout(row.item.txn_ref)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(amount)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(email_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(sms_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(due_date)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(approved_time)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(email_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(sms_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(approved_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Approved',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(verified_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Verified',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                          'badge-soft-secondary': `${row.value}` === '',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': `${row.value}` === 'Success',
                          'badge-soft-warning':
                            `${row.value}` === 'Initiated' ||
                            `${row.value}` === 'Created',
                          'badge-soft-pending':
                            `${row.value}` === 'Pending',
                          'badge-soft-danger':
                            `${row.value}` === 'Failed' ||
                            `${row.value}` === 'Cancelled' ||
                            `${row.value}` === 'Rejected',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:head()="data">
                      <b-form-checkbox
                        v-if="data.column === 'selectpayout'"
                        v-model="selectAvailableInPage"
                        id="select_all"
                        name="select_all"
                        :value="true"
                        :unchecked-value="false"
                        @change="selectAllInPage"
                      ></b-form-checkbox>
                      <span
                        v-else
                        v-b-tooltip.hover.top
                        :title="$t(data.field.tooltip)"
                      >
                        {{ $t(data.label) }}
                      </span>
                    </template>
                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                    <div class="d-flex justify-content-between mb-3">
                    <!-- pagination -->
                      <per-page
                        v-model="perPage"
                        :currentPage="currentPage"
                        :totalRows="rows"
                        :tableRowsCount="payoutsDataFromStore.length"
                        @input="refreshRecord"
                      />
                      <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="refreshRecord" ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isMasterMerchant && activeTab === 'approve_payout'" >
              <div v-if="currentPageTitle == 'not-approved'">
                <div class="d-flex" style="align-items: center; font-size: 14px;padding: 16px 24px;color: rgba(0, 0, 0, 0.70);font-weight: 325;background: #FFFAEA;border-radius: 12px;border: 1px #FEF2CF solid;margin-bottom: 25px;"
                v-if="!$store.state.auth.currentUser.is_2fa_enabled">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="vuesax/linear/info-circle"><g id="info-circle">
                      <path id="Vector" d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Vector_2" d="M12 8V13" stroke="#FC6B2D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Vector_3" d="M11.9941 16H12.0031" stroke="#FC6B2D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g></g>
                  </svg>
                  <span style="margin-left: 16px;">
                    Two-factor (2FA) Authentication is required to approve Payouts. 
                    <router-link to="/payouts/payout-settings"><span style="text-decoration: underline;font-weight: 350;" class="text-chai">{{ $t("views.payouts.click_here") }}</span></router-link> 
                    to Enable 2FA for your profile.
                  </span>
                </div>
                
                <div class="mb-3">
                  <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="d-flex justify-content-between">
                    <!-- :options="searchByOptions" -->
                      <search-by v-model="searchBy" @search-by-clicked="refreshRecord" :hideSearchButton="true" />
                      <div class="d-flex">
                        <b-btn
                          v-if="userPermissions.includes('approve-payouts')"
                          variant="primary" 
                          class="cancel bg-white text-dark"
                          @click="approveModal('reject')"
                          :disabled="selectedTransactionRefs.length === 0"
                          style="0px 16px"
                        >
                          {{ $t("views.payouts.reject") }}
                        </b-btn>
                          <button
                          v-if="userPermissions.includes('approve-payouts')"
                          class="btn btn-primary ml-2"
                          @click="approveModal('approve')"
                          :disabled="selectedTransactionRefs.length === 0"
                          style="0px 16px"
                        >
                           {{ $t("views.payouts.approve") }}
                          </button>
                      </div>
                    </div>
                  </div>
                  </div>
                  <!-- <b-alert
                    variant="warning"
                    class="text-center mx-auto"
                  >
                    {{selectAllTransactions ? `All ${payoutTotalElementsFromStore}` : selectedTransactionRefs.length }} Transactions are selected.
                    <b-btn variant="link" size="sm" v-if="!selectAllTransactions" class="p-0 text-info" @click="selectAllTxn">select all {{ payoutTotalElementsFromStore }} Transactions.</b-btn>
                  </b-alert> -->
                  <div class="mt-4" style="font-size: 12px;color: rgba(0, 0, 0, 0.50);" v-if="selectedTransactionRefs.length > 0">
                    Selected <span class="text-chai">{{selectAllTransactions ? payoutTotalElementsFromStore : selectedTransactionRefs.length}} {{selectedTransactionRefs.length > 1 ? `Rows` : `Row` }}</span> out of {{ payoutTotalElementsFromStore }}. <span @click="selectAllTxn" style="text-decoration: underline;" class="text-chai cursor-pointer">SELECT</span> all at once here.
                  </div>
                </div>
                <div class="">
                  <b-table
                    class="table-centered text-center table-custom app-very-table"
                    :items="payoutsDataFromStore"
                    :fields="fields"
                    responsive="sm"
                    :bordered="false"
                    sticky-header="50vh"
                    show-empty
                    :empty-text="$t('common.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:cell(selectpayout)="row">
                      <b-form-checkbox
                        :id="`customercheck${row.item.txn_ref}`"
                        :name="`customercheck${row.item.txn_ref}`"
                        :disabled="
                          row.item.approved_status === 'Approved' ||
                          row.item.status != 'Created'
                        "
                        :checked="selectedTransactionRefs.includes(row.item.txn_ref)"
                        @change="toggleSelectPayout(row.item.txn_ref)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(amount)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(email_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(sms_notification_sent)="row">
                      <img src="@/assets/images/check-circle.svg" v-if="row.value">
                      <img src="@/assets/images/x-circle.svg" v-else>
                    </template>

                    <template v-slot:cell(due_date)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(approved_time)="row">
                      <div>
                        <span class="align-middle">{{
                          getFormattedDate(row.value)
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(email_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(sms_sent)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Sent',
                          'badge-soft-danger': row.value === 'Failed' || row.value === 'Not Sent',
                          'badge-soft-warning': row.value === 'Waiting',
                          'badge-soft-secondary':
                            row.value === '' ||
                            row.value === 'N/A',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(approved_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Approved',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(verified_status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': row.value === 'Verified',
                          'badge-soft-danger': row.value === 'Rejected' || row.value === 'Cancelled',
                          'badge-soft-pending': row.value === 'Pending',
                          'badge-soft-secondary': `${row.value}` === '',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(status)="row">
                      <div
                        class="badge font-size-12"
                        style="padding: 6px 12px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                        :class="{
                          'badge-soft-success': `${row.value}` === 'Success',
                          'badge-soft-warning':
                            `${row.value}` === 'Initiated' ||
                            `${row.value}` === 'Created',
                          'badge-soft-pending':
                            `${row.value}` === 'Pending',
                          'badge-soft-danger':
                            `${row.value}` === 'Failed' ||
                            `${row.value}` === 'Cancelled' ||
                            `${row.value}` === 'Rejected',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:head()="data">
                      <b-form-checkbox
                        v-if="data.column === 'selectpayout'"
                        v-model="selectAvailableInPage"
                        id="select_all"
                        name="select_all"
                        :value="true"
                        :unchecked-value="false"
                        @change="selectAllInPage"
                      ></b-form-checkbox>
                      <span
                        v-else
                        v-b-tooltip.hover.top
                        :title="$t(data.field.tooltip)"
                      >
                        {{ $t(data.label) }}
                      </span>
                    </template>
                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                  <div class="d-flex justify-content-between mb-3">
                    <!-- pagination -->
                      <per-page
                        v-model="perPage"
                        :currentPage="currentPage"
                        :totalRows="rows"
                        :tableRowsCount="payoutsDataFromStore.length"
                        @input="refreshRecord"
                      />
                      <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="refreshRecord" ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Payouts modal -->
    <!-- <div>
      <PayoutForm :editItem="editItem" :isEdit="isEdit" />
    </div> -->

    <div>
      <b-modal
        id="modalmobile"
        centered
        v-model="enterMobile"
        title="Enter Mobile Number"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modalmobile" @submit.prevent="GenerateOtp">
          <div class="form-group">
            <label for="Mobile">{{ $t("views.payouts.mobile") }}</label>
            <input
              type="tel"
              name="mobile"
              v-model="payoutMobile.mobileNo"
              class="form-control"
              :class="{
                'is-invalid': submitted && $v.payoutMobile.mobileNo.$error,
              }"
            />
            <div
              v-if="submitted && !$v.payoutMobile.mobileNo.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.mobile") }}
            </div>
          </div>

          <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideMobile">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-success ml-1">
              {{ $t("button.submit") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>

    <!-- OTP input for approval -->
    <div>
      <b-modal
        size="md"
        id="modal"
        centered
        v-model="showApprove"
        title=""
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
        header-class="otp-modal-header-custom"
        body-class="otp-modal-body-custom"
      >
        <form id="modal" class="text-center" @submit.prevent="ApprovePayments">
          <div class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="45" viewBox="0 0 26 45" fill="none">
              <g id="Union">
              <mask id="path-1-inside-1_2_108045" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z"/>
              </mask>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z" fill="#DCDAD5" fill-opacity="0.3"/>
              <path d="M23.2143 6.42857V8.92857H25.7143V6.42857H23.2143ZM2.5 6.42857H0V8.92857H2.5V6.42857ZM23.2143 38.5714H25.7143V36.0714H23.2143V38.5714ZM2.5 38.5714V36.0714H0V38.5714H2.5ZM19.7143 0H6V5H19.7143V0ZM25.7143 6C25.7143 2.68629 23.028 0 19.7143 0V5C20.2666 5 20.7143 5.44771 20.7143 6H25.7143ZM25.7143 6.42857V6H20.7143V6.42857H25.7143ZM2.5 8.92857H23.2143V3.92857H2.5V8.92857ZM0 6V6.42857H5V6H0ZM6 0C2.68629 0 0 2.68629 0 6H5C5 5.44772 5.44771 5 6 5V0ZM2.5 38.5714V6.42857H-2.5V38.5714H2.5ZM-2.5 6V6.42857H2.5V6H-2.5ZM6 -2.5C1.30558 -2.5 -2.5 1.30558 -2.5 6H2.5C2.5 4.067 4.067 2.5 6 2.5V-2.5ZM19.7143 -2.5H6V2.5H19.7143V-2.5ZM28.2143 6C28.2143 1.30558 24.4087 -2.5 19.7143 -2.5V2.5C21.6473 2.5 23.2143 4.067 23.2143 6H28.2143ZM28.2143 6.42857V6H23.2143V6.42857H28.2143ZM23.2143 6.42857V38.5714H28.2143V6.42857H23.2143ZM28.2143 39V38.5714H23.2143V39H28.2143ZM19.7143 47.5C24.4087 47.5 28.2143 43.6944 28.2143 39H23.2143C23.2143 40.933 21.6473 42.5 19.7143 42.5V47.5ZM6 47.5H19.7143V42.5H6V47.5ZM-2.5 39C-2.5 43.6944 1.30558 47.5 6 47.5V42.5C4.067 42.5 2.5 40.933 2.5 39H-2.5ZM-2.5 38.5714V39H2.5V38.5714H-2.5ZM25.7143 39V38.5714H20.7143V39H25.7143ZM19.7143 45C23.028 45 25.7143 42.3137 25.7143 39H20.7143C20.7143 39.5523 20.2666 40 19.7143 40V45ZM6 45H19.7143V40H6V45ZM0 39C0 42.3137 2.68629 45 6 45V40C5.44772 40 5 39.5523 5 39H0ZM0 38.5714V39H5V38.5714H0ZM23.2143 36.0714H2.5V41.0714H23.2143V36.0714Z" fill="black" mask="url(#path-1-inside-1_2_108045)"/>
              </g>
              </svg>
              <div style="margin-top: 24px;line-height: 20px;margin-bottom: 12px;">{{$t('views.payouts.otp_verification')}}</div>
              <div style="color: rgba(0, 0, 0, 0.30);font-size: 12px;margin-bottom: 25px;">{{ $t("views.payouts.otp_msg") }}</div>
              <div style="margin-top: 32px;line-height: 20px;font-size: 12px;">{{ $t("views.payouts.enter_otp") }}</div>
          </div>
          <div class="mt-2 form-group">
            <OtpInput
              id="otpVal1"
              name="otp"
              :input-classes="
                submitted && $v.payoutApprove.otp.$error
                  ? 'is-invalid otp-input form-control'
                  : 'otp-input form-control'
              "
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOtpChange"
            />
            <div
              v-if="submitted && !$v.payoutApprove.otp.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.otp") }}
            </div>

          <div style="margin-top: 20px;">
              <b-form-checkbox
                v-model="payoutApprove.saveOtp"
                :value="true"
                :unchecked-value="false"
                class="mt-2"
              >
                Do not ask for authentication for the next 10 minutes
              </b-form-checkbox>
            <button style="margin: 0 auto;line-height: 20px;padding: 12px 140px; display: block;margin-top: 20px;" type="submit" class="btn btn-primary">{{ $t('button.submit') }}</button>
            <span style="margin-top: 16px;display: inline-block;font-size: 12px;margin-bottom: 40px;"> Didn’t get the code? <span class="text-chai" style="cursor: pointer;" @click="resendOTP">{{ $t("views.payouts.resend_otp") }}</span></span>
          </div>
          </div>

          <!-- <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideApprove">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-primary ml-1">
              {{ $t("button.submit") }}
            </button>
          </div> -->
        </form>
      </b-modal>
    </div>

    <!-- OTP input for verify -->
    <div>
      <b-modal
        size="md"
        id="modal"
        centered
        v-model="showVerify"
        title=""
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
        header-class="otp-modal-header-custom"
        body-class="otp-modal-body-custom"
      >
        <form id="modal" class="text-center" @submit.prevent="VerifyPayments">
            <div class="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="45" viewBox="0 0 26 45" fill="none">
              <g id="Union">
              <mask id="path-1-inside-1_2_108045" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z"/>
              </mask>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.5H19.7143C21.6473 2.5 23.2143 4.067 23.2143 6V6.42857H2.5V6C2.5 4.067 4.067 2.5 6 2.5ZM0 38.5714V6.42857V6C0 2.68629 2.68629 0 6 0H19.7143C23.028 0 25.7143 2.68629 25.7143 6V6.42857V38.5714V39C25.7143 42.3137 23.028 45 19.7143 45H6C2.68629 45 0 42.3137 0 39V38.5714ZM23.2143 38.5714V39C23.2143 40.933 21.6473 42.5 19.7143 42.5H6C4.067 42.5 2.5 40.933 2.5 39V38.5714H23.2143Z" fill="#DCDAD5" fill-opacity="0.3"/>
              <path d="M23.2143 6.42857V8.92857H25.7143V6.42857H23.2143ZM2.5 6.42857H0V8.92857H2.5V6.42857ZM23.2143 38.5714H25.7143V36.0714H23.2143V38.5714ZM2.5 38.5714V36.0714H0V38.5714H2.5ZM19.7143 0H6V5H19.7143V0ZM25.7143 6C25.7143 2.68629 23.028 0 19.7143 0V5C20.2666 5 20.7143 5.44771 20.7143 6H25.7143ZM25.7143 6.42857V6H20.7143V6.42857H25.7143ZM2.5 8.92857H23.2143V3.92857H2.5V8.92857ZM0 6V6.42857H5V6H0ZM6 0C2.68629 0 0 2.68629 0 6H5C5 5.44772 5.44771 5 6 5V0ZM2.5 38.5714V6.42857H-2.5V38.5714H2.5ZM-2.5 6V6.42857H2.5V6H-2.5ZM6 -2.5C1.30558 -2.5 -2.5 1.30558 -2.5 6H2.5C2.5 4.067 4.067 2.5 6 2.5V-2.5ZM19.7143 -2.5H6V2.5H19.7143V-2.5ZM28.2143 6C28.2143 1.30558 24.4087 -2.5 19.7143 -2.5V2.5C21.6473 2.5 23.2143 4.067 23.2143 6H28.2143ZM28.2143 6.42857V6H23.2143V6.42857H28.2143ZM23.2143 6.42857V38.5714H28.2143V6.42857H23.2143ZM28.2143 39V38.5714H23.2143V39H28.2143ZM19.7143 47.5C24.4087 47.5 28.2143 43.6944 28.2143 39H23.2143C23.2143 40.933 21.6473 42.5 19.7143 42.5V47.5ZM6 47.5H19.7143V42.5H6V47.5ZM-2.5 39C-2.5 43.6944 1.30558 47.5 6 47.5V42.5C4.067 42.5 2.5 40.933 2.5 39H-2.5ZM-2.5 38.5714V39H2.5V38.5714H-2.5ZM25.7143 39V38.5714H20.7143V39H25.7143ZM19.7143 45C23.028 45 25.7143 42.3137 25.7143 39H20.7143C20.7143 39.5523 20.2666 40 19.7143 40V45ZM6 45H19.7143V40H6V45ZM0 39C0 42.3137 2.68629 45 6 45V40C5.44772 40 5 39.5523 5 39H0ZM0 38.5714V39H5V38.5714H0ZM23.2143 36.0714H2.5V41.0714H23.2143V36.0714Z" fill="black" mask="url(#path-1-inside-1_2_108045)"/>
              </g>
              </svg>
              <div style="margin-top: 24px;line-height: 20px;margin-bottom: 12px;">{{$t('views.payouts.otp_verification')}}</div>
              <div style="color: rgba(0, 0, 0, 0.30);font-size: 12px;margin-bottom: 25px;">{{ $t("views.payouts.otp_msg") }}</div>
              <div style="margin-top: 32px;line-height: 20px;font-size: 12px;">{{ $t("views.payouts.enter_otp") }}</div>
          </div>
          <div class="mt-2 form-group">
            <OtpInput
              id="otpVal"
              name="otp"
              :input-classes="
                submitted && $v.payoutVerify.otp.$error
                  ? 'is-invalid otp-input form-control'
                  : 'otp-input form-control'
              "
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              v-model="payoutVerify.otp"
              @on-change="handlePayoutOtpChange"
            />
            <div
              v-if="submitted && !$v.payoutVerify.otp.required"
              class="invalid-feedback"
            >
              {{ $t("views.payouts.required_field_msg.otp") }}
            </div>

          <div style="margin-top: 20px;">
            <b-form-checkbox
              v-model="payoutVerify.saveOtp"
              :value="true"
              :unchecked-value="false"
              class="mt-2"
            >
              Do not ask for authentication for the next 10 minutes
            </b-form-checkbox>
            <button style="margin: 0 auto;line-height: 20px;padding: 12px 140px; display: block;margin-top: 20px;" type="submit" class="btn btn-primary">{{ $t('button.submit') }}</button>
            <span style="margin-top: 16px;display: inline-block;font-size: 12px;margin-bottom: 40px;"> Didn’t get the code? <span class="text-chai" style="cursor: pointer;" @click="resendOTPForVerify">{{ $t("views.payouts.resend_otp") }}</span></span>
          </div>
          </div>

          <!-- <div class="text-right">
            <b-button class="mr-1" variant="light" @click="hideVerify">
              {{ $t("button.close") }}
            </b-button>

            <button type="submit" class="btn btn-primary ml-1">
              {{ $t("button.submit") }}
            </button>
          </div> -->
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style>
.swal2-container {
  z-index: 9999;
}
</style>

<style lang="css" scoped>
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #payouts-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}
.file_upload{
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.file-input-draggable {
  border-radius: 12px;
  border: 1px dashed #DCDAD5;
  background: rgba(220, 218, 213, 0.30);
}
.file_upload::file-selector-button {
  display: none;
}
.bulk-upload-button {
  text-align: center;
  color: #b3afaf;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  padding: 15px 10px;
  border: 1px dashed #cbcbcb;
  margin-top: 40px;
}
.cursor-pointer {
  cursor: pointer;
}
.avatar-custom {
  height: 70px;
  width: 95px;
}
@media (max-width: 700px) {
  .right-bar {
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar {
    width: 320px;
    right: -320px;
  }
}

.uploads {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploads1 {
  margin-top: 20%;
  display: flex;
  padding: 0 20px;
}
.uploads2 {
  width: 200px;
}
::v-deep .custom-datepicker .xmx-input{
  height: 35px !important;
  padding-top: 4px;
  min-height: unset;
}
@media (max-width: 1300px) {
  .right-bar {
    width: 360px;
    right: -360px;
  }
}
@media (max-width: 400px) {
  .right-bar {
    width: 320px;
    right: -320px;
  }
}
.create-payout-button {
    padding: 0px 16px;
    height: 44px;
    top: 50%;
    transform: translateY(-50%);
}
::v-deep .form-control{
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
.payout-form-top .btn{
  border-radius: 4px;
}
::v-deep  .statement-table .table > thead > tr > th:nth-child(2) {
    border-radius: 12px 0 0 12px;
}
::v-deep  .app-very-table .table > thead > tr > th:nth-last-child(2) {
    border-radius: 0px 12px 12px 0px;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-pending{
    background: rgba(252, 107, 45, 0.10);
    color: #FC6B2D;
}
::v-deep .show_details_payout button{
    padding: 7px 9px;
    border-radius: 8px;
    background: rgba(220, 218, 213, 0.30);
    height: 36px;
}
::v-deep .show_details_payout ul{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    transform: translate3d(-205px, 0px, 0px)!important;
    z-index: 1!important;
}
::v-deep .show_details_payout ul button{
    padding: 12px 25px;
    border-radius: 0px;
    background-color: transparent;
    height: auto;
}
.right-top-section{
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 500px;
  right: -500px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.plan-view-section{
  margin-bottom: 16px;
}
.plan-view-section .plan-view-heading{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.30);
    line-height: 20px;
}
.plan-view-section .plan-view-value{
    font-size: 14px;
    line-height: 20px;
    padding-top: 4px;
}
.sms-email-success{
  color: #219653;
}
.sms-email-danger{
  color: #DE3535;
}
.sms-email-warning{
  color: #fcb92c;
}
.sms-email-secondary{
  color: #74788d;
}
.upload-disabled {
  opacity: 0.5;
}
::v-deep .otp-input.form-control{
  width: 38px;
  height: 40px;
  border-radius: 12px;
  margin-left: 10px;
}
::v-deep #otpVal, ::v-deep #otpVal1{
      justify-content: center;
}
</style>
<style >
  .otp-modal-header-custom{
      border-bottom: none;
      padding-bottom: 0px;
  }
  .otp-modal-body-custom{
      padding: 16px 40px 16px 40px;
  }
</style>
